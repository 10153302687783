
import { CommonModule } from "@angular/common";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from "@angular/router";
import { ToastModalComponent } from "@components/toast-modal/toast-modal.component";
import { ToastStoreService } from "@dataFormatter/store/toast";
import { ExportModalComponent } from "@components/export-modal/export-modal.component";
import { ExportStoreService } from "@dataFormatter/store/toast/export";
import { AuthService } from "@services/auth/auth.service";
import { PreloaderComponent } from "@pages/enterprise-modules/components/preloader/preloader.component";


@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, ToastModalComponent, CommonModule, ExportModalComponent, PreloaderComponent],

  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss"
})
export class AppComponent implements OnInit {
  toastData: any;
  exportData: any;
  loading = false;
  constructor(
    public toastService: ToastStoreService,
    public exportService: ExportStoreService,
    public authService: AuthService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ){
    this.toastService.toastData$.subscribe((data) => {
      this.toastData = data;
    });
    this.exportService.toastData$.subscribe((data) => {
      this.exportData = data;
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(event =>{
      if (event instanceof NavigationStart){
        this.loading = true;
      }
      if (event instanceof NavigationEnd) {
        this.loading = false;
      }
    });
  }

  ngAfterViewInit() {
    // this.authService.showLoader$.subscribe((data) => {
    //   if(data.loading){
    //     this.loading = true;
    //     this.cdr.detectChanges();
    //   }else{
    //     this.loading = false;
    //     this.cdr.detectChanges();
    //   }
    // });
  }

}
