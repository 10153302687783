import { Injectable } from "@angular/core";
import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { AuthService } from "@services/auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {


  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (req.url.includes("ps-billing-dev.seamhealth.support/api/") || req.url.includes("billing.clientshot.com/api/")) {
      return next.handle(req);
    } else if (req.url.includes("api/feedback/complaint/commendation")) {
      return next.handle(req);
    } else if (req.url.includes("v1/projects/clientshot/assessments?key")){
      return next.handle(req);
    }else if (req.url.includes("api.geoapify.com/v1/ipinfo")){
      return next.handle(req);
    }
    else {
      const tokenizedReq = req.clone({
        setHeaders: {
          Authorization: this.authService.getAuthToken(),
        },
      });
      return next.handle(tokenizedReq);
    }
  }

  constructor(private authService: AuthService) {}
}
