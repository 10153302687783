import { HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "@services/auth/auth.service";
import { Router } from "@angular/router";
import { ToastStoreService } from "../store/toast";
export class GeneralUtil {
  static toast: ToastStoreService;
  // static authService = AuthService;
  static route: Router;

  constructor(
    public toast: ToastStoreService,
    private authService: AuthService,
    private router: Router
  ) {}

  static removeUnderscore(mystring: string): string {
    if (this.isValidString(mystring)) {
      return mystring.replace(/_/g, " ");
    } else {
      return null!;
    }
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * 14 Apr 2022 dateformat
   */
  static formatDate(value: string | number | Date) {
    const date = new Date(value);
    const day = date.toLocaleString("default", { day: "2-digit" });
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.toLocaleString("default", { year: "numeric" });
    return day + " " + month + " " + year;
  }

  /*** Convert   date to *  dd/mm/yyyy dateformat*/
  static convertDate(inputFormat: string | number | Date) {
    function pad(s: any) {
      return s < 10 ? "0" + s : s;
    }
    const d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  /**
   * Convert 2000-08-12T13:06:46.631+01:00 datetimeformat to
   * 12-09-2020 dateformat
   */
  static convertDateTimeToDateformat(dateTime: string) {
    if (!this.isValidString(dateTime)) {
      return null;
    }

    const dateString = dateTime.split("T")[0];
    const dateArray = dateString.split("-");
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }

  // Error handling
  static errorHandler(error: HttpErrorResponse) {
    console.log(error.error.status, "...inside util");
    if (error instanceof HttpErrorResponse) {
      if (error?.error && error?.error === "Internal Server Error") {
        return error.error;
      } else if (error?.error.error === "Internal Server Error") {
        return error.error.error;
      } else if (
        (error?.error?.message &&
          (error?.error?.message.includes(
            "User session expired due to a new user login"
          ) ||
            error?.error?.message.includes(
              "The token is expired and not valid anymore"
            ))) ||
        error.error.status === 401 ||
        error.error.status === "UNAUTHORIZED"
      ) {
        localStorage.clear();
        localStorage.removeItem("PS-USER-DETAILS-EMAIL");
        localStorage.removeItem("PS-USER-DETAILS-FULL-NAME");
        localStorage.removeItem("PS-USER-DETAILS-ROLE");
        localStorage.removeItem("PS-USER-DETAILS-TOKEN");
        localStorage.removeItem("PS-USER-COMPANY");
        localStorage.removeItem("PS-USER-COMPANY-ID");
        localStorage.removeItem("PS-USER-DETAILS-ID");
        localStorage.removeItem("PS-COMPANY-CODE");
        localStorage.removeItem("companyLogo");
        localStorage.removeItem("USER-DETAILS-PERMISSIONS");
        localStorage.removeItem("isEnterpriseUser");
        window.location.reload();
        return error.error.message;
      } else if (error?.error?.errors && error?.error?.errors[0].message) {
        return error.error.errors[0].message;
      } else if (error.error.status >= 400 && error.error.status <= 499) {
        return error.error.message;
      } else if (error.error.status >= 500 && error.error.status <= 599) {
        return "Server not responding, try again later";
      } else if (
        error.error.statusCode === 400 &&
        error.error.status === "FAILED"
      ) {
        return error.error.errors[0].message;
      }
    }
    return "Network error";
  }

  static isValidJSON(json: unknown): boolean {
    if (json === null || json === "" || json === undefined) {
      return false;
    }

    const str = JSON.stringify(json);

    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }

    return true;
  }

  static isValidString(astring: any): boolean {
    if (astring !== undefined && astring !== "" && astring !== null) {
      return true;
    }
    return false;
  }

  static generateTempId(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  logout(this: GeneralUtil): void {
    this.authService.resetUserDetails();
  }
}
