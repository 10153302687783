
import { IFormBuilder } from "./formBuilder/initialState";
import { formsReducer } from "./formBuilder/reducer";
export interface AppState {

  formBuild: IFormBuilder;
}

export const appReducer = {

  formBuild: formsReducer,
};
