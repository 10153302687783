@if (toastData?.display) {
  <div
    class="Toastbody"
    [ngClass]="
    toastData?.position == 'leftTop'
      ? 'Toastbody leftTop'
      : toastData?.position == 'rightTop'
      ? 'Toastbody rightTop'
      : toastData?.position == 'leftBottom'
      ? 'Toastbody leftBottom'
      : 'Toastbody rightBottom'
    "

  >
    <div class="feedback-toast">
      <div class="feedback-body">
        <span class="icon">
          @if (toastData.title == toast_Enum.Error_occurred) {
            <img [src]="AppIcons.warning"  />
          }
          @else if (toastData.title === toast_Enum.warning) {
            <img [src]="AppIcons.alert_triangle" />
          }@else if (toastData.title == toast_Enum.You_are_offline) {
            <img [src]="AppIcons.cloud_off" />
          }
          @else if (
            toastData.title !== toast_Enum.You_are_offline &&
            toastData.title !== toast_Enum.Error_occurred &&
            toastData.title !== toast_Enum.warning
          ) { <img [src]="AppIcons.checkIcon"  /> }
        </span>
        <div class="feedback-txt">
          <div class="txt-title">{{ toastData.title }}</div>
          <div class="txt-body">
            {{ toastData.message }}
          </div>
        </div>
      </div>
      <div class="close-div" (click)="closeToastModal()">
        <img [src]="AppIcons.x" />
      </div>
    </div>
  </div>

}
