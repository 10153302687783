import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./guards/auth/auth.guard";
import { NgModule } from "@angular/core";
import { PreventAuthGuard } from "./guards/prevent/preventh-auth.guard";

const UserTypeModule = () =>
  import("./user-type/user-type-routing.module").then(
    (x) => x.UserTypeRoutingModule
  );

export const routes: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadComponent: () =>
      import("./pre-oauth-pages/user-sign-up/sign-in/sign-in.component").then(
        (mod) => mod.SignInComponent
      ),
    canActivate: [PreventAuthGuard],
  },
  { path: "", loadChildren: UserTypeModule },
  {
    path: "sign-in",
    pathMatch: "full",
    loadComponent: () =>
      import("./pre-oauth-pages/user-sign-up/sign-in/sign-in.component").then(
        (mod) => mod.SignInComponent
      ),
    canActivate: [PreventAuthGuard],
  },
  {
    path: "sign-up",
    pathMatch: "full",
    loadComponent: () =>
      import("./pre-oauth-pages/user-sign-up/sign-up/sign-up.component").then(
        (mod) => mod.SignUpComponent
      ),
  },
  {
    path: "signin/:id/:code/:user",
    pathMatch: "full",
    loadComponent: () =>
      import("./pre-oauth-pages/user-sign-up/sign-in/sign-in.component").then(
        (mod) => mod.SignInComponent
      ),
    canActivate: [PreventAuthGuard],
  },
  {
    path: "terms-and-conditons",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/terms-and-conditions/terms-and-conditions.component"
      ).then((mod) => mod.TermsAndConditionsComponent),
  },
  {
    path: "download-app",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/download-app/download-app.component"
      ).then((mod) => mod.DownloadAppComponent),
  },
  {
    path: "delete-account",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/delete-account/delete-account.component"
      ).then((mod) => mod.DeleteAccountComponent),
  },
  {
    path: "privacy-policy",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/privacy-policy/privacy-policy.component"
      ).then((mod) => mod.PrivacyPolicyComponent),
  },
  {
    path: "forgot-password",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/forgot-password/forgot-password.component"
      ).then((mod) => mod.ForgotPasswordComponent),
  },
  {
    path: "resend-invitation/:companyName",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/reset-invitation/reset-invitation.component"
      ).then((mod) => mod.ResetInvitationComponent),
    canActivate: [PreventAuthGuard],
  },
  {
    path: "reset-password/:userId/:activationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/reset-password/reset-password.component"
      ).then((mod) => mod.ResetPasswordComponent),
  },
  {
    path: "verify-email/:userId/:activationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/verify-email/verify-email.component"
      ).then((mod) => mod.VerifyEmailComponent),
  },
  {
    path: "company-setup/:userId/:activationCode/:creationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/company-setup/company-setup.component"
      ).then((mod) => mod.CompanySetupComponent),
  },
  {
    path: "company-setup/:userId/:creationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/company-setup/company-setup.component"
      ).then((mod) => mod.CompanySetupComponent),
  },
  {
    path: "register-invited-team/:companyName/:userId/:activationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/register-invited-team/register-invited-team.component"
      ).then((mod) => mod.RegisterInvitedTeamComponent),
  },
  {
    path: "create-initial-branch",
    pathMatch: "full",
    loadComponent: () =>
      import("@pages/initial-branch/initial-branch.component").then(
        (mod) => mod.InitialBranchComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "login-invited-team/:companyName/:userId/:activationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/user-sign-up/login-invited-team/login-invited-team.component"
      ).then((mod) => mod.LoginInvitedTeamComponent),
  },

  // end here
  {
    path: "",
    loadChildren: () =>
      import("./pages/custom/custom.module").then((m) => m.CustomModule),
  },

  {
    path: "questionnaire-flow",
    loadChildren: () =>
      import("./pages/custom-flow-modules/custom-flow-modules.module").then(
        (m) => m.CustomFlowModulesModule
      ),
  },

  {
    path: "web/:companyName",
    loadChildren: () =>
      import("./pages/flow-modules/flow-modules.module").then(
        (m) => m.FlowModulesModule
      ),
  },
  {
    path: "create-initial-branch",
    pathMatch: "full",
    loadComponent: () =>
      import("@pages/initial-branch/initial-branch.component").then(
        (mod) => mod.InitialBranchComponent
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "create-branch",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "@pages/main-app-modules/branch-pages/create-new-branch/create-new-branch.component"
      ).then((mod) => mod.CreateNewBranchComponent),
    canActivate: [AuthGuard],
  },
  {
    path: "email-template-verification/:companyCode/:email/:verificationCode",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/verify-email-sender/verify-email-sender.component"
      ).then((mod) => mod.VerifyEmailSenderComponent),
  },
  {
    path: "join-workspace/:userId",
    pathMatch: "full",
    loadComponent: () =>
      import(
        "./pre-oauth-pages/join-workspace/join-workspace.component"
      ).then((mod) => mod.JoinWorkspaceComponent),
  },
  {
    path: "**",
    loadComponent: () =>
      import("@pages/page-not-found/page-not-found.component").then(
        (mod) => mod.PageNotFoundComponent
      ),
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRouteModule {}
