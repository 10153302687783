export interface IFormBuilder {
  formTitle: string;
  formType: string;
  facilityName: string;
  companyName: string;
  sections: IFormBuilderSection[];
  buttonColor: string;
  backgroundColor: string;
  backgroundStyle: string;
  showFormConsent: boolean;
  languageOption: string[];
  showLanguageOption: boolean;
}

export interface IFormBuilderSection {
  sectionTitle: string;
  description: string;
  additionalInfo: string;
  logo: string;
  id: string;
  questions: IFormBuilderQuestion[];
}

export interface IFormBuilderDropdown {
  label: string;
  dropDownValueList: string[];
}

export interface IFormBuilderCondition {
  ifCondition: string;
  optionSelected: string;
  thenCondition: string;
  toQuestion: number[];
}

export interface IFormBuilderQuestion {
  question: string;
  id: string;
  logo: string;
  questionType: string;
  description: string;
  additionalDescription: string;
  placeholder: string;
  options: object;
  nextPages: object;
  dropDownLabel: string;
  linkServicePoint: boolean;
  showVisual: boolean;
  ratingNumber: string;
  validation: string;
  addOthers: boolean;
  linearData: {
    minData: string;
    maxData: string;
  };
  numberTypeData: {
    format: string;
    points: string;
  };
  timeFormat: string;
  dateFormat: string;
  phoneNumberFormat: string;
  countryCodeSelected: string;
  required: boolean;
  charts: string;
  enableLogic: boolean;
  dropdownOptionList: IFormBuilderDropdown[];
  addressElementArray: string[];
  setPhoneAsComplaintContact: boolean;
  setEmailAsComplaintContact: boolean;
}

export const initialState: IFormBuilder = {
  formTitle: "",
  formType: "",
  facilityName: "",
  companyName: "",
  sections: [],
  buttonColor: "#8a6cc8",
  backgroundColor: "#8a6cc8",
  backgroundStyle: "default",
  showFormConsent: false,
  languageOption: ["English"],
  showLanguageOption: false,
};
