import { Component, Input, OnInit } from "@angular/core";
import { ToastStoreService } from "../../../store/toast/index";
import { ToastStoreDto } from "../../../store/toast/store.dto";
import { CommonModule } from "@angular/common";
import { AppIcons } from "@constant/icon-constant";
import { toast_Enum } from "@dataFormatter/dto/Toast-enum";

@Component({
  selector: "app-toast-modal",
  templateUrl: "./toast-modal.component.html",
  styleUrls: ["./toast-modal.component.scss"],
  standalone: true,
  imports: [CommonModule],
  providers: [ToastStoreService],
})
export class ToastModalComponent implements OnInit {
  AppIcons = AppIcons;
  toast_Enum = toast_Enum;
  @Input() toastData: any;
  public updatedToastDate!: ToastStoreDto;

  ngOnInit(): void {}
  constructor(public toastService: ToastStoreService) {}

  ngOnChanges() {
    this.updatedToastDate = this.toastData;
  }

  closeToastModal() {
    this.toastService.clearStore();
    this.toastData.display = false;
  }
}
