import { RegisterTeamRequestDto } from "@dataFormatter/dto/signin-dto";
import { CreateCompanyDto } from "@dataFormatter/dto/signin-dto";
import {
  SignUpDto,
  userProfileDto,
  ChangePasswordDto,
} from "@dataFormatter/dto/signin-dto";
import { SignInDto } from "@dataFormatter/dto/signin-dto";
import { Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { ResendVerificationDto } from "@dataFormatter/dto/invite-user.dto";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable } from "rxjs";
import {
  ForgotPasswordEntity,
  ResetPasswordEntity,
} from "@dataFormatter/entity/reset-password.entity";
import { verifyEmailDto } from "@dataFormatter/dto/verifyEmail";
import { BehaviorSubject } from "rxjs";
import { GeneralUtil } from "@dataFormatter/utils/general-util";

import { Router } from "@angular/router";
import { UploadImageDto } from "@dataFormatter/dto/upload-image-dto";
import { ToastStoreService } from "../../../store/toast";
import { LocalstorageServiceService } from "@services/localstorage/localstorage-service.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public static userDetailsEmail = "PS-USER-DETAILS-EMAIL";
  public static userDetailsView = "PS-USER-DETAILS-VIEW";
  public static userDetailsFullName = "PS-USER-DETAILS-FULL-NAME";
  public static userDetailsRole = "PS-USER-DETAILS-ROLE";
  public static userDetailsRoleList = "PS-USER-DETAILS-ROLE-LIST";
  public static userDetailsToken = "PS-USER-DETAILS-TOKEN";
  public static userCompany = "PS-USER-COMPANY";
  public static userPrimaryCompany = "PS-USER-PRIMARY-COMPANY";
  public static userCompanyId = "PS-USER-COMPANY-ID";
  public static userDetailsId = "PS-USER-DETAILS-ID";
  public static companyCode = "PS-COMPANY-CODE";
  public static PrimaryCompanyCode = "PS-PRIMARY-COMPANY-CODE";
  public static companyState = "PS-COMPANY-STATE";
  public static companyType = "PS-COMPANY-TYPE";
  public static userPermissions = "USER-DETAILS-PERMISSIONS";
  public static isEnterpriseUser = "isEnterpriseUser";
  public static userProfile = "PS-USERPROFILE";
  public static socketDetail = "PS-SOCKET-TOKEN";
  public static userPlanPermission = "PS-USER-PLAN-PERMISSIONS";
  public static companyIcon = "companyIcon";
  public static companyUserSize = "COMPANY-USER-SIZE";
  public static userCurrentLocation = "USER-CURRENT-LOCATION";

  private apiData = new BehaviorSubject<any>(null);
  public apiData$ = this.apiData.asObservable();

  public path = environment.apiurl;
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    public toastService: ToastStoreService,
    public localStorage: LocalstorageServiceService
  ) {}
  // Get Token value
  getToken() {
    return localStorage.getItem(AuthService.userDetailsToken);
  }
  getCompanyName() {
    return localStorage.getItem(AuthService.userCompany);
  }
  isAnEnterpriseUser() {
    const user = this.getUserDetailItem("isEnterpriseUser");
    if (user !== "undefined") {
      return JSON.parse(user);
    } else {
      return false;
    }
  }
  // Get Authorization Token
  getAuthToken() {
    if (this.getToken() === null) {
      return "";
    } else {
      return "Bearer " + this.getToken();
    }
  }
  public planHasPermission(permission: string) {
    const permissions = this.getUserDetailItem(
      AuthService.userPlanPermission
    ).split(",");
    if (permissions) {
      return permissions.includes(permission);
    } else {
      return false;
    }
  }

  public getUserDetailItem(userDetailItem: string): string {
    const userDetail = localStorage.getItem(userDetailItem);
    if (!GeneralUtil.isValidString(userDetail)) {
      return null!;
    }
    return userDetail!;
  }

  // Sign In
  signInUser(signInDto: SignInDto) {
    const url = environment.apiurl + "login";
    return this.httpClient.post<any>(url, signInDto).pipe();
  }

  // Full Sign In
  fullSignInUser(signInDto: { isAdminLogin: boolean; companyCode: string }) {
    const url = environment.apiurl + "auth/login/pick-company";
    return this.httpClient.post<any>(url, signInDto).pipe();
  }

  //Update user Profile
  updateProfile(profile: userProfileDto) {
    const url = `${environment.apiurl}auth/user/update`;
    return this.httpClient.put<any>(url, profile).pipe();
  }

  oauthLogin(payload: any) {
    const url = environment.apiurl + "login/oauth";
    return this.httpClient.post<any>(url, payload).pipe();
  }

  // Verify User
  verifyUser(verifyDto: verifyEmailDto) {
    const url = environment.apiurl + "verify-user";
    return this.httpClient.post<any>(url, verifyDto).pipe();
  }

  // get one contact
  public getUserByUserId(id: string) {
    const url = environment.apiurl + "/user/email/" + id;
    return this.httpClient.get<any>(url).pipe();
  }

  // Create Company
  createCompany(createCompany: CreateCompanyDto) {
    const url = environment.apiurl + "create/company";
    return this.httpClient.post<any>(url, createCompany).pipe();
  }

  // Sign Up
  signUpUser(signUpDto: SignUpDto) {
    const url = environment.apiurl + "register";
    return this.httpClient.post<any>(url, signUpDto).pipe();
  }

  // Register Invited Team
  registerInvitedTeam(registerInvitedTeamDto: RegisterTeamRequestDto) {
    const url = environment.apiurl + "register/team";
    return this.httpClient.post<any>(url, registerInvitedTeamDto).pipe();
  }
  resendVerification(resendEmailDto: ResendVerificationDto) {
    const url = environment.apiurl + "resend-verification";
    return this.httpClient.post<any>(url, resendEmailDto).pipe();
  }

  // here we set/change value of the observable
  setData(data: any) {
    this.apiData.next(data);
  }

  // Forgot Password
  forgotPassword(forgotPasswordPayload: ForgotPasswordEntity) {
    const url = environment.apiurl + "forgot/password";
    return this.httpClient.post<ForgotPasswordEntity>(
      url,
      forgotPasswordPayload
    );
  }

  // Confirm if user is logged in
  isLoggedIn(): boolean {
    return (
      !!localStorage.getItem(AuthService.userDetailsToken) &&
      !!localStorage.getItem(AuthService.userDetailsRole)
    );
  }

  // Forgot Password
  resetPassword(resetPasswordPayload: ResetPasswordEntity) {
    const url = environment.apiurl + "reset/password";
    return this.httpClient.put<ResetPasswordEntity>(url, resetPasswordPayload);
  }
  // Verify Email
  verifyEmail(verifyUser: verifyEmailDto) {
    const url = environment.apiurl + "verify-user";
    return this.httpClient.post<ResetPasswordEntity>(url, verifyUser);
  }

  //

  signInMessagingSocket(login: any) {
    const url = environment.socketUrl + "login";
    return this.httpClient.post<any>(url, login).pipe();
  }

  // after sign-in, set user details
  public setUserDetails(data: any) {
    const rolelist: any = JSON.stringify(data.role.roles);
    const permission: any = [...data.permissions, ...data.planPermissions];
    this.localStorage.saveData(AuthService.userDetailsEmail, data.email);
    this.localStorage.saveData(
      AuthService.userDetailsView,
      data.hasViewedCreateBranch ? data.hasViewedCreateBranch : false
    );
    this.localStorage.saveData(AuthService.userDetailsRole, data.role.role);
    this.localStorage.saveData(AuthService.userDetailsRoleList, rolelist);
    this.localStorage.saveData(AuthService.userDetailsToken, data.token);
    this.localStorage.saveData(AuthService.userCompany, data.companyDto.name);
    this.localStorage.saveData(
      AuthService.userPrimaryCompany,
      data.companyDto.name
    );
    this.localStorage.saveData(
      AuthService.companyUserSize,
      data.companyDto.size
    );
    this.localStorage.saveData(AuthService.userCompanyId, data.companyDto.id);
    this.localStorage.saveData(
      AuthService.PrimaryCompanyCode,
      data.companyDto.companyCode
    );
    this.localStorage.saveData(AuthService.userDetailsId, data.userId);
    this.localStorage.saveData(AuthService.userPermissions, data.permissions);
    this.localStorage.saveData(
      AuthService.companyCode,
      data.companyDto.companyCode
    );
    this.localStorage.saveData(
      AuthService.companyType,
      data.companyDto.companyType
    );
    this.localStorage.saveData(AuthService.companyState, data.companyDto.state);
    this.localStorage.saveData(
      AuthService.isEnterpriseUser,
      data.isEnterpriseUser
    );
    this.localStorage.saveData(
      AuthService.userDetailsFullName,
      data.userFullName
    );
    this.localStorage.saveData(AuthService.userProfile, data.userProfile);
    this.localStorage.saveData(AuthService.userPlanPermission, permission);
  }

  public setMiniUserDetails(data: any) {
    this.localStorage.saveData(AuthService.userDetailsEmail, data.email);
    this.localStorage.saveData(AuthService.userDetailsToken, data.token);
    this.localStorage.saveData(AuthService.userDetailsId, data.userId);
    this.localStorage.saveData(
      AuthService.userDetailsFullName,
      data.userFullName
    );
  }

  //after change of company name

  public setCompanyName(name: string) {
    this.localStorage.saveData(AuthService.userCompany, name);
  }

  //after creation of branch
  public updateUserDetails(data: any) {
    const rolelist: any = JSON.stringify(data.role.roles);
    const permission: any = [...data.permissions, ...data.planPermissions];
    //this.localStorage.saveData(AuthService.userDetailsEmail, data.email);
    this.localStorage.saveData(
      AuthService.userDetailsView,
      data.hasViewedCreateBranch
    );
    this.localStorage.saveData(AuthService.userDetailsRole, data.role.role);
    this.localStorage.saveData(AuthService.userDetailsRoleList, rolelist);
    //this.localStorage.saveData(AuthService.userDetailsToken, data.token);
    this.localStorage.saveData(AuthService.userCompany, data.companyDto.name);
    this.localStorage.saveData(
      AuthService.userPrimaryCompany,
      data.companyDto.name
    );
    //this.localStorage.saveData(AuthService.userCompanyId, data.companyDto.id);
    this.localStorage.saveData(
      AuthService.PrimaryCompanyCode,
      data.companyDto.companyCode
    );
    this.localStorage.saveData(
      AuthService.companyUserSize,
      data.companyDto.size
    );
    this.localStorage.saveData(AuthService.userDetailsId, data.userId);
    this.localStorage.saveData(AuthService.userPermissions, data.permissions);
    this.localStorage.saveData(
      AuthService.companyCode,
      data.companyDto.companyCode
    );
    this.localStorage.saveData(
      AuthService.companyType,
      data.companyDto.companyType
    );
    this.localStorage.saveData(AuthService.companyState, data.companyDto.state);
    this.localStorage.saveData(
      AuthService.isEnterpriseUser,
      data.isEnterpriseUser
    );
    this.localStorage.saveData(
      AuthService.userDetailsFullName,
      data.userFullName
    );
    this.localStorage.saveData(AuthService.userPlanPermission, permission);
    this.localStorage.saveData(AuthService.userProfile, data.userProfile);
  }

  public setSwitchedCompanyDetails(data: any) {
    const rolelist: any = JSON.stringify(data.role.roles);
    const permission: any = [...data.permissions, ...data.planPermissions];
    this.localStorage.saveData(AuthService.userCompany, data.companyDto.name);
    this.localStorage.saveData(AuthService.userCompanyId, data.companyDto.id);
    // this.localStorage.saveData(AuthService.userDetailsToken, data.token);
    this.localStorage.saveData(
      AuthService.companyType,
      data.companyDto.companyType
    );
    this.localStorage.saveData(
      AuthService.companyCode,
      data.companyDto.companyCode
    );
    this.localStorage.saveData(AuthService.userDetailsRole, data.role.role);
    this.localStorage.saveData(AuthService.userDetailsRoleList, rolelist);
    this.localStorage.saveData(
      AuthService.isEnterpriseUser,
      data.isEnterpriseUser
    );
    this.localStorage.saveData(AuthService.userPermissions, data.permissions);
    this.localStorage.saveData(
      AuthService.companyUserSize,
      data.companyDto.size
    );
    this.localStorage.saveData(AuthService.userDetailsId, data.userId);
    this.localStorage.saveData(AuthService.userDetailsEmail, data.email);
    this.localStorage.saveData(AuthService.companyState, data.companyDto.state);
    this.localStorage.saveData(
      AuthService.userDetailsView,
      data.hasViewedCreateBranch
    );
    this.localStorage.saveData(
      AuthService.userDetailsFullName,
      data.userFullName
    );
    this.localStorage.saveData(AuthService.userPlanPermission, permission);
    this.localStorage.saveData(AuthService.userProfile, data.userProfile);
  }
  public resetUserPermission(data: Array<string>) {
    const getallPermission: any = localStorage.getItem(
      AuthService.userPermissions
    );
    const permissionArray: Array<string> = getallPermission.split(",");
    let permission: any;
    if (data) {
      permission = [...permissionArray, ...data];
    } else {
      permission = [...permissionArray];
    }
    this.localStorage.saveData(AuthService.userPlanPermission, permission);
  }
  //setSocketDetail

  public saveSocketTokenDetail(data: any) {
    this.localStorage.saveData(AuthService.socketDetail, data.access_token);
  }

  // reset user details
  public resetUserDetails() {
    this.localStorage.removeData(AuthService.userDetailsEmail);
    this.localStorage.removeData(AuthService.userDetailsView);
    this.localStorage.removeData(AuthService.userDetailsFullName);
    this.localStorage.removeData(AuthService.userDetailsToken);
    this.localStorage.removeData(AuthService.userDetailsRole);
    this.localStorage.removeData(AuthService.userDetailsId);
    this.localStorage.removeData(AuthService.userCompany);
    this.localStorage.removeData(AuthService.userPrimaryCompany);
    this.localStorage.removeData(AuthService.companyUserSize);
    this.localStorage.removeData(AuthService.userCompanyId);
    this.localStorage.removeData(AuthService.PrimaryCompanyCode);
    this.localStorage.removeData(AuthService.companyState);
    this.localStorage.removeData(AuthService.companyCode);
    this.localStorage.removeData(AuthService.companyType);
    this.localStorage.removeData("companyLogo");
    this.localStorage.removeData(AuthService.userPermissions);
    this.localStorage.removeData(AuthService.isEnterpriseUser);
    this.localStorage.removeData(AuthService.userProfile);
    this.localStorage.removeData(AuthService.socketDetail);
    this.localStorage.getData(AuthService.userPlanPermission);
    this.localStorage.removeData("branch-filter");
    this.localStorage.removeData("date-filter");
    this.localStorage.removeData("form-companyName");
    this.localStorage.removeData("form-companyId");
    this.localStorage.removeData("form-userPermissions");
    this.localStorage.removeData(AuthService.userCurrentLocation);
    // del("companyId");
    // del("userPermissions");
  }

  hasPermission(userPermission: string): boolean {
    let allowUser = false;
    if (!this.getUserDetailItem(AuthService.userPlanPermission)) {
      if (
        this.getUserDetailItem(AuthService.isEnterpriseUser) &&
        this.getUserDetailItem(AuthService.userDetailsRole) === "DATA_COLLECTOR"
      ) {
        this.resetUserDetails();
        this.router.navigateByUrl("download-app");
      } else {
        this.toastService.setError(
          "User does not have permission. Please try logging in again"
        );
        this.resetUserDetails();
        this.router.navigateByUrl("/sign-in");
      }
    }
    const permissions = this.getUserDetailItem(
      AuthService.userPlanPermission
    ).split(",");
    if (!permissions) {
      this.resetUserDetails();
      this.router.navigateByUrl("/sign-in");
    } else {
      if (permissions.length === 0) {
        this.resetUserDetails();
        this.router.navigateByUrl("/sign-in");
      }
      permissions.map((permission: string) => {
        if (permission === userPermission) {
          allowUser = true;
        }
      });
    }
    return allowUser;
  }

  public loginWithGoogle(credentials: string): Observable<any> {
    const header = new HttpHeaders().set("Content-type", "application/json");
    return this.httpClient.post(
      this.path + "LoginWithGoogle",
      JSON.stringify(credentials),
      { headers: header }
    );
  }
  public errorHandler(error: HttpErrorResponse) {
    this.toastService.setError(this.errorMethod(error));
  }
  // Error Handler
  errorMethod(error: HttpErrorResponse) {
    if (error instanceof HttpErrorResponse) {
      if (error?.error && error?.error === "Internal Server Error") {
        return error.error;
      }
      if (error?.error?.message) {
        if (error?.error?.message === "Token expired") {
          this.resetUserDetails();
          this.router.navigateByUrl("/login", { replaceUrl: true });
        } else if (error?.error?.message === "Invalid token") {
          this.resetUserDetails();
          this.router.navigateByUrl("/login", { replaceUrl: true });
        }
        if (error.error.message instanceof Array) {
          return error.error.message[0];
        } else {
          return error.error.message;
        }
      }
      if (error?.error?.errors[0].message) {
        return error.error.errors[0].message;
      }
    }
  }

  updateProfilePicture(data: UploadImageDto) {
    //const details = {"userId" : userId,"pictureUpload" : pictureUpload}
    const url = `${environment.apiurl}auth/user/update/image`;
    return this.httpClient.put<any>(url, data).pipe();
  }

  updatePassword(data: ChangePasswordDto) {
    const url = `${environment.apiurl}/auth/user/change/password`;
    return this.httpClient.put<any>(url, data);
  }

  deleteimage(id: string) {
    const url = `${environment.apiurl}/auth/user/${id}/image`;
    return this.httpClient.delete<any>(url);
  }
}
