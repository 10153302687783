import { Action, createReducer, on } from "@ngrx/store";
import {
  getFormBuild,
  postFormType,
  postFacilityName,
  postSections,
  setCompanyName,
  submitForm,
  postFormTitle,
  postForm,
  postBackgroundColor,
  postButtonColor,
  postLanguageSupportToggle,
  postConsentPageToggle,
  postLanguageOptions,
  resetStore,
  postBackgroundStyle,
} from "./actions";
import { initialState } from "./initialState";

const _formsReducer = createReducer(
  initialState,
  on(getFormBuild, (state) => {
    return {
      ...state,
    };
  }),
  on(submitForm, (state, action) => {
    return {
      ...action.form,
    };
  }),
  on(postFacilityName, (state, action) => {
    return {
      ...state,
      facilityName: action.facilityName,
    };
  }),
  on(postFormType, (state, action) => {
    return {
      ...state,
      formType: action.formType,
    };
  }),
  on(postFormTitle, (state, action) => {
    return {
      ...state,
      formTitle: action.formTitle,
    };
  }),
  on(postSections, (state, action) => {
    return {
      ...state,
      sections: action.sections,
    };
  }),
  on(setCompanyName, (state, action) => {
    return {
      ...state,
      companyName: action.companyName,
    };
  }),
  on(postForm, (state, action) => {
    return {
      ...state,
      ...action.form,
    };
  }),

  on(postButtonColor, (state, action) => {
    return {
      ...state,
      buttonColor: action.buttonColor,
    };
  }),

  on(postBackgroundColor, (state, action) => {
    return {
      ...state,
      backgroundColor: action.backgroundColor,
    };
  }),
  on(postBackgroundStyle, (state, action) => {
    return {
      ...state,
      backgroundStyle: action.backgroundStyle,
    };
  }),
  on(postLanguageSupportToggle, (state, action) => {
    return {
      ...state,
      showLanguageOption: action.toggleLanguage,
    };
  }),
  on(postConsentPageToggle, (state, action) => {
    return {
      ...state,
      showFormConsent: action.toggleConsent,
    };
  }),
  on(postLanguageOptions, (state, action) => {
    return {
      ...state,
      languageOption: action.languageOptions,
    };
  }),
  on(resetStore, (state, action) => {
    return {
      ...initialState,
    };
  }),
);
export function formsReducer(state: any, action: Action) {
  return _formsReducer(state, action);
}
