import { ApplicationConfig } from "@angular/core";
import { provideRouter } from "@angular/router";

import { routes } from "./app.routes";
import { provideClientHydration } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { TokenInterceptorService } from "@directives/token-interceptor.service";
import { provideCharts, withDefaultRegisterables } from "ng2-charts";
import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { environment } from "@environments/environment";



export const appConfig: ApplicationConfig = {
  providers: [provideRouter(routes), provideClientHydration(), provideHttpClient(
    withInterceptorsFromDi(),
  ),
  provideCharts(withDefaultRegisterables())
  ,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  },
  {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.siteKey,
  },
  {
    provide: RECAPTCHA_SETTINGS,
    useValue: {
      siteKey:environment.siteKey
    }

  }

  ]
};
