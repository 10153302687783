import { Component, Input, OnInit } from "@angular/core";
import { ToastStoreDto } from "../../../store/toast/store.dto";
import { CommonModule } from "@angular/common";
import { AppIcons } from "@constant/icon-constant";
import { toast_Enum } from "@dataFormatter/dto/Toast-enum";
import { ToastStoreService } from "@dataFormatter/store/toast";
import { ExportStoreService } from "@dataFormatter/store/toast/export";

@Component({
  selector: "app-export-modal",
  templateUrl: "./export-modal.component.html",
  styleUrls: ["./export-modal.component.scss"],
  standalone: true,
  imports: [CommonModule],
  providers: [ToastStoreService],
})
export class ExportModalComponent implements OnInit {
  AppIcons = AppIcons;
  toast_Enum = toast_Enum;

  position = "";
  
  @Input() toastData: any;
  @Input() checktoastData: any;
  public updatedToastDate!: ToastStoreDto;

  ngOnInit(): void {}
  constructor(public exportService: ExportStoreService, public toastService: ToastStoreService) {
  }

  ngOnChanges() {
    this.updatedToastDate = this.toastData;
    if((this.checktoastData.title.length > 1) && (this.checktoastData.position === "rightBottom")){
      this.position = "calc(100vh - 200px)";
      const timer = setTimeout(() => {
        this.position = "calc(100vh - 100px)";
        clearTimeout(timer);
      }, this.checktoastData.timeCount);
    }
  }

  closeToastModal() {
    this.exportService.clearStore();
    this.toastData.display = false;
  }
}
